<template>
  <div class="assessment">
    <v-container class="mt-10">
      <v-breadcrumbs :items="navigation" large class="pl-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>

      <h1 class="mb-5">{{ $t("assessment.title") }}</h1>
      <v-card>
        <v-card-text class="text-center pa-10">
          <h2>{{ question.question[locale] }}</h2>
          <v-select
            v-model="answer"
            :items="question.answersArray"
            :label="question.label[locale]"
            :placeholder="question.placeholder[locale]"
            :multiple="question.isMultiple"
            :chips="question.isMultiple"
            deletable-chips
            outlined
            class="mt-5"
          >
            <template v-slot:prepend-item v-if="question.isMultiple">
              <v-list-item ripple @click="toggleAll">
                <v-list-item-action>
                  <v-icon v-if="!answer || answer.length === 0">
                    mdi-check-box-multiple-outline
                  </v-icon>
                  <v-icon v-else>
                    mdi-checkbox-blank-outline
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-if="!answer || answer.length === 0">{{
                    toggleTextAll()
                  }}</v-list-item-title>
                  <v-list-item-title v-else>
                    {{ $t("assessment.none") }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-show="question.previousQuestionId !== null"
            :disabled="question.previousQuestionId === null"
            text
            @click="next(true)"
          >
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t("assessment.questionBack") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!answer || answer.length === 0"
            text
            @click="next(false)"
          >
            {{ $t("assessment.questionContinue") }}
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import dependencies
import { mapGetters } from "vuex";

export default {
  name: "Question",
  data: () => ({
    answer: null
  }),
  computed: {
    ...mapGetters("assessment", ["questions", "answers"]),
    navigation() {
      return [
        { text: this.$t("assessment.title"), disabled: true },
        {
          text: `${this.$t("assessment.question")} ${
            this.question.questionId
          } ${this.$t("assessment.of")} ${this.questions.length}`,
          disabled: true
        }
      ];
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    question() {
      if (this.$route.params.id !== undefined) {
        let q = this.questions.find(
          o => o.questionId.toString() === this.$route.params.id.toString()
        );
        q.answersArray = q.answers(this.$store)(this.locale);
        return q;
      }
      // return first question as default
      // otherwise we'll have errors when changing from assessment to bildungsangebote
      return this.questions[0];
    }
  },
  watch: {
    // reset answer if is already selected
    question() {
      if (
        this.answers.map(o => o.questionId).includes(this.question.questionId)
      )
        this.answer = this.answers.find(
          o => o.questionId === this.question.questionId
        ).answer;
    }
  },
  methods: {
    toggleTextAll() {
      let toggleTextAll = ["3", "4", "5"].includes(this.question.questionId)
        ? this.$t("assessment.doesNotMatter")
        : this.$t("assessment.all");
      return toggleTextAll;
    },
    toggleAll() {
      if (!this.answer || this.answer.length === 0)
        this.answer = this.question.answersArray;
      else this.answer = [];
    },
    next(prev) {
      if (prev)
        return this.$router.push({
          name: "question",
          params: { id: this.question.previousQuestionId }
        });

      // get answers from store
      let answers = this.answers;

      // overwrite answer if already answered
      if (answers.map(o => o.questionId).includes(this.question.questionId)) {
        answers.forEach(o => {
          if (o.questionId === this.question.questionId) o.answer = this.answer;
        });
      } else {
        // push new answer
        answers.push({
          questionId: this.question.questionId,
          answer: this.answer
        });
      }

      this.$store.dispatch("assessment/setAnswers", answers);
      this.question.nextId(this.$router, this.answer);
      this.answer = null;
    }
  }
};
</script>
