<template>
  <div class="assessment">
    <v-container class="mt-10">
      <v-breadcrumbs :items="navigation" large class="pl-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <h1 class="mb-5">{{ $t("assessment.result") }}</h1>
      <trainings-table :trainings="results"></trainings-table>
    </v-container>
  </div>
</template>

<script>
// import dependencies
import { mapGetters } from "vuex";
import TrainingsTable from "@/components/TrainingsTable.vue";

export default {
  name: "Result",
  components: {
    TrainingsTable
  },
  computed: {
    ...mapGetters("trainings", {
      trainings: "trainings"
    }),
    ...mapGetters("assessment", ["questions", "answers"]),
    locale() {
      return this.$root.$i18n.locale;
    },
    navigation() {
      return [
        {
          text: this.$t("assessment.title"),
          to: { name: "question", params: { id: 1 } }
        },
        { text: this.$t("assessment.result"), disabled: true }
      ];
    },
    results() {
      // add back response options Hochschulabschluss and Abschluss höhere Berufsbildung
      // if the user selected something falling into either of the categories.

      let answer1 = this.answers.find(a => a.questionId === "1").answer;
      if (this.locale === "de") {
        if (
          answer1.some(
            answer => answer.includes("Master") || answer.includes("Bachelor")
          )
        ) {
          answer1.push("Hochschulabschluss");
        }

        if (
          answer1.some(answer =>
            ["Eidg. Diplom HF", "Eidg. Diplom", "Eidg. Fachausweis"].includes(
              answer
            )
          )
        ) {
          answer1.push("Abschluss höhere Berufsbildung");
        }
      }
      if (this.locale.lang === "fr") {
        if (
          answer1.some(
            answer => answer.includes("Master") || answer.includes("Bachelor")
          )
        ) {
          answer1.push("Diplôme d'une haute école");
        }

        if (
          answer1.some(answer =>
            ["Diplôme fédéral DF", "Diplôme ES", "Brevet fédéral"].includes(
              answer
            )
          )
        ) {
          answer1.push("Diplôme de formation professionnelle supérieure");
        }
      }

      /*let answer1_1 = this.answers.find(a => a.questionId === "1-2").answer;
      let answer1_2 = this.answers.find(a => a.questionId === "1-3").answer;
      let answer1_5 = this.answers.find(a => a.questionId === "1-5").answer;*/
      let answer2 = this.answers.find(a => a.questionId === "2").answer;
      let answer3 = this.answers.find(a => a.questionId === "3").answer;
      let answer4 = this.answers.find(a => a.questionId === "4").answer;
      let answer5 = this.answers.find(a => a.questionId === "5").answer;

      return this.trainings
        .filter(
          o =>
            o.zulassungsbedingungenAbschluss[this.locale] === undefined ||
            o.zulassungsbedingungenAbschluss[this.locale].some(za =>
              answer1.includes(za)
            )
        )
        .filter(
          o =>
            o.arbeitsweltBranche[this.locale] === undefined ||
            o.arbeitsweltBranche[this.locale].some(a => answer2.includes(a))
        )
        .filter(
          o =>
            o.berufsfeldZihlmann[this.locale] === undefined ||
            o.berufsfeldZihlmann[this.locale].some(a => answer3.includes(a))
        )
        .filter(
          o =>
            o.berufsinteressenEgloff[this.locale] === undefined ||
            o.berufsinteressenEgloff[this.locale].some(a => answer4.includes(a))
        )
        .filter(
          o =>
            o.sprachen[this.locale] === undefined ||
            o.sprachen[this.locale].some(a => answer5.includes(a))
        );
    }
  },
  created() {}
};
</script>
